body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
}
.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
}

.thumb {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.carousel .thumb.selected {
    border: 2px solid #077d92 !important;
    border-radius: 4px;
}

.carousel .thumb:hover {
    border: 2px solid #077d92 !important;
    border-radius: 4px;
}

.swiper {
    width: 100%;
    z-index: 100;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    z-index: 100;
}

.swiper-slide img {
    width: 100%;
}
.swiper-slide {
    overflow: hidden;
}

.swiper-pagination {
    margin-bottom: 50px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    padding: 50px;
    font-size: 50px;
    font-weight: 700;
}

.swiper-button-next,
.swiper-button-prev {
    width: 0;
}

ul.slick-dots {
    bottom: 0px;
    background: #ffffff59;
    align-items: center;
    display: flex;
    margin: 0;
    padding: 0;
}

.slick-dots li {
    width: 15px;
    height: 0;
    margin: 0;
    padding: 0;
    top: -5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    color: #000;
}

*::-webkit-scrollbar {
    width: 6px; /* ширина всего скроллбара */
    height: 6px; /* высота скроллбара */
}

*::-webkit-scrollbar-track {
    background: rgb(240, 240, 240); /* цвет области трека */
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(199, 198, 198); /* цвет ползунка */
    border-radius: 20px; /* закругление ползунка */
}

.HiddenScroll::-webkit-scrollbar {
    display: none;
}
